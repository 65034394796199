<template>
  <b-card
    class="tm-card"
    border-variant="light"
    header-tag="header"
    style="max-width: 50rem"
  >
    <b-card-title>{{ localTimeIn }}....{{ localTimeOut }}</b-card-title>
    <b-card-body>
      <b-row>
        <b-col>
          <b-button v-on:click="handleDetailButton" variant="primary"
            >See Details</b-button
          >
        </b-col>
        <b-col>
          <b-button v-on:click="handleLocationButton" variant="primary">
            <span>See Map</span>
            <b-icon-geo-alt-fill class="mx-2"></b-icon-geo-alt-fill>
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
    <b-collapse v-model="isDetailShown">
      <b-card-text class="my-4">
        Name:
        <p class="mx-4">{{ userName }}</p>
        Recorded Time IN:
        <p class="mx-4">{{ siteTimeIn }}</p>
        Recorded Time OUT:
        <p v-if="clockOutDate != null" class="mx-4">{{ siteTimeOut }}</p>
        <b-container v-if="clockOutDate == null">
          <b-row>
            <b-col md="4">
              <b-time
                hide-header
                class="m-2"
                v-model="manualClockTime"
              ></b-time>
            </b-col>
            <b-col md="5">
              <b-form-datepicker
                class="m-2"
                value-as-date
                hide-header
                v-model="manualClockDate"
                style="margin: auto"
              ></b-form-datepicker>
            </b-col>
          </b-row>
          <b-button v-on:click="handleManualClockDate"> 
            <b-spinner :hidden="!isUpdating" small class="mx-1"></b-spinner>
            <span>Manual Clock Out</span>
          </b-button>
        </b-container>
        Site Address:
        <p class="mx-4">{{ siteAddress }}</p>
        User-ID:
        <p class="mx-4">{{ userId }}</p>
      </b-card-text>
    </b-collapse>
    <b-collapse v-model="isLocationShown">
      <span>Geo Coordinates: ({{ latitude }}, {{ longitude }})</span>
      <b-card-body
        v-bind:id="mapKitID"
        style="width: auto; height: 30rem; max-width: 40rem"
      ></b-card-body>
    </b-collapse>
    <template #header>
      <b-card-title style="margin: auto"
        >{{ siteName }} : {{ vendorName }}</b-card-title
      >
    </template>
  </b-card>
</template>

<script>
import { BIconGeoAltFill } from "bootstrap-vue";
import { createMapkit } from "vue-mapkit";
// import siteService from './SiteService'
import Vue from "vue";
import VueMapkit from "vue-mapkit";
import siteService from './SiteService';

Vue.use(VueMapkit, {
  authorizationCallback(done) {
    // provide mapkit jwt here
    // done(process.env.VUE_APP_MAPKIT_JWT)
    done(
      "eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IjZNOUpGV0RaNFEifQ.eyJpc3MiOiJTTDk1SkY1NTM3IiwiaWF0IjoxNjQ0MDMzNzg3LCJleHAiOjE2NzUzODI0MDB9.46tT3Gll1ZR0NoBFOYkm1mo-VZ96E3e2LfnW3FHqDAF9qbBxrxBvYyf9dX7B5ttt724Cn00R3jQv1O5zYs0goA"
    );
  },
  // you can use options from mapkit documentation as well
  language: "en",
});

export default {
    name: "Admin",
    components: {
        BIconGeoAltFill,
    },
    props: {
        id: String, 
        siteId: String,
        clockIn: String,
        clockOut: String,
        siteName: String,
        vendorName: String,
        userName: String,
        siteAddress: String,
        userId: String,
        latitude: String,
        longitude: String,
        punchType: String,
        timeZone: String,
    },
    data() {
        return {
            mapKitID: `map-${this._uid}`,
            isLocationShown: false,
            isDetailShown: false,
            clockInDate: null,
            clockOutDate: null,
            manualClockDate: null,
            manualClockTime: "",
            isUpdating: false,
        };
    },
    computed: {
        localTimeIn() {
            var timeString = "";
            if (this.clockInDate) {
                timeString = this.clockInDate.toLocaleTimeString("en-US", {
                timeZoneName: "short",
                });
            } else {
                timeString = "";
            }
            return timeString;
        },
        localTimeOut() {
            var timeString = "";
            if (this.clockOutDate) {
                timeString = this.clockOutDate.toLocaleTimeString("en-US", {
                timeZoneName: "short",
                });
            } else {
                timeString = "<No Clock Out>";
            }
            return timeString;
        },
        siteTimeIn() {
            var result = "";
            if (this.clockInDate) {
                result = new Intl.DateTimeFormat("en-us", {
                dateStyle: "short",
                timeStyle: "long",
                timeZone: this.timeZone,
                }).format(this.clockInDate);
            } else {
                result = "";
            }
            return result;
        },
        siteTimeOut() {
            var result = "";
            if (this.clockOutDate) {
                result = new Intl.DateTimeFormat("en-us", {
                dateStyle: "short",
                timeStyle: "long",
                timeZone: this.timeZone,
                }).format(this.clockOutDate);
            } else {
                result = "<No Clock Out>";
            }
            return result;
        },
        clockType() {
            if (this.punchType == "PUNCH_IN") {
                return "Clock IN";
            } else {
                return "Clock OUT";
            }
        },
    },
    mounted: function () {
        this.parseDate();
    },
    methods: {
        async configureMap() {
            // Test Coords: @28.5463951,-81.2810008,15.42z

            console.log("Initializing MapKit");
            createMapkit(this.mapKitID, { language: "en" }).then((map) => {
                var newCenter = new window.mapkit.Coordinate(Number(this.latitude), Number(this.longitude));
                var annotation = new window.mapkit.MarkerAnnotation(newCenter, {
                    title: "Clock In Location (Approximate)",
                });
                this.map = map;
                this.map.showsCompass = false;
                this.map.showsMapTypeControl = false;
                this.map.showsUserLocationControl = false;
                this.map.showsZoomControl = false;
                this.map
                    .setCenterAnimated(newCenter, true)
                    .setCameraDistanceAnimated(3000, true)
                    .addAnnotation(annotation);
            });
        },
        handleLocationButton() {
            this.isLocationShown = !this.isLocationShown;
            if (this.map == null) {
                this.configureMap();
            } else {
                // this.map.destroy()
            }
        },
        handleDetailButton() {
            console.log(`Detail State: ${this.isDetailShown}`);
            this.isDetailShown = !this.isDetailShown;
        },
        handleManualClockDate() {
            let time = this.manualClockTime.split(":");
            let hours = time[0];
            let minutes = time[1];
            let result = this.manualClockDate;
            result.setHours(hours, minutes);

            this.isUpdating = true;
            siteService.putClockOut(this.id, result).then(response => {
                console.log(response.data);
                this.clockOutDate = new Date(response.data.clockOut);
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.isUpdating = false;
            });
        },
        parseDate() {
            this.clockInDate = new Date(this.clockIn);
            this.manualClockDate = new Date(this.clockIn);

            if (this.clockOut) {
                this.clockOutDate = new Date(this.clockOut);
            }
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.map-container {
  width: 100%;
  height: 600px;
}
</style>
