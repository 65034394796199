<template>
    <b-card v-if="(isAuthenticated || isLoading)" style="max-width:30rem;" class="m-4">
        <b-card-title>
            Your Profile
        </b-card-title>
        <b-card-body>Email: {{userEmail}}</b-card-body>
        <b-button variant="secondary" v-on:click="logout">Log Out</b-button>
    </b-card>
</template>

<script>
import { Auth0Client } from '@auth0/auth0-spa-js'

export default {
    name: 'UserProfile',
    components: {
      // BIconExclamationTriangleFill
    },
    props: {
        auth: Auth0Client
    },
    data() {
        return {
            isLoading: false,
            isAuthenticated: false,
            user: null,
        }
    },
    watch: {
        auth: function() {
            console.log(`Login Prompt Update Auth: ${this.auth}`);
            if (this.auth) {
                this.isLoading = false;
                this.checkAuthStatus();
                this.getCurrentUser();
            }
        }
    },
    mounted: function() {
        console.log(`Login Prompt Mount: ${this.auth}`);
    },
    computed: {
        userEmail: function() {
            if (this.user) {
                return this.user.email;
            } else {
                return "<none on file>"
            }
        },
    },
    methods: {
        logout() {
            this.auth.logout({returnTo: `${window.location.origin}/#/admin`});
        },
        async checkAuthStatus() {
            this.isAuthenticated = await this.auth.isAuthenticated();
            console.log(`Authentication Status: ${this.isAuthenticated}`);
        },
        async getCurrentUser() {
            this.user = await this.auth.getUser();
        }
    }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
