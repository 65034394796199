<template>
    <b-card class="b-card tm-card" border-variant="light" footer-tag="footer">
        <b-card-title>
            <b-form-input 
                        size="lg" 
                        v-model="model.name" 
                        v-bind:plaintext="!isEditing"
                        placeholder="New Site">
            </b-form-input>
        </b-card-title>
        <b-form-input 
            size="md" 
            v-model="model.vendorName" 
            v-bind:plaintext="!isEditing"
            placeholder="New Vendor Name"
            class="my-1">
        </b-form-input>
        <b-form-input 
            size="md" 
            v-model="model.address" 
            v-bind:plaintext="!isEditing"
            placeholder="New Address"
            class="my-1">
        </b-form-input>
        <b-form-input size="md" v-model="model.sitePin" v-bind:plaintext="true"></b-form-input>
        <template #footer>
            <b-row align-h="between">
                <b-col style="margin:auto">
                    <b-container v-bind:hidden='!isErrorSaving'>
                        Unable to save! 
                        <b-icon-exclamation-triangle-fill variant="danger"></b-icon-exclamation-triangle-fill>
                    </b-container>
                </b-col>
                <b-col sm="1" style="margin:auto">
                        <b-spinner v-bind:hidden='!isSaving'></b-spinner>
                </b-col>
                <b-col sm="2" style="text-align:right; margin:auto">
                    <b-button 
                        v-on:click="handleEditButton" 
                        v-bind:variant="isEditing ? 'outline-primary' : 'outline-secondary'">
                        {{isEditing ? "Save" : "Edit"}}
                    </b-button>
                </b-col>
            </b-row>
        </template>
    </b-card>
</template>

<script>
import { BIconExclamationTriangleFill } from 'bootstrap-vue'
import siteService from './SiteService' 
// var Client = require('node-rest-client').Client;
// var client = new Client();

export default {
  name: 'Site',
  components: {
      BIconExclamationTriangleFill
  },
  props: {
    id: String,
    name: String,
    vendorName: String,
    address: String,
    sitePin: String,
    isNewSite: Boolean
  },
  data() {
    return {
        isNew: this.isNewSite, 
        isEditing: this.isNewSite,
        model: {
            id: this.id,
            name: this.name,
            vendorName:  this.vendorName,
            address: this.address,
            sitePin: 'Site PIN:  ' + this.sitePin
        },
        isSaving: false,
        isErrorSaving: false
    }
  },
  methods: {
      handleEditButton() {
          if (this.isEditing) {
              this.saveAction()
          } else {
              this.isEditing = !this.isEditing
          }
      },
      saveAction() {
          this.isSaving = true;
          if (this.isNew) {
              this.model.id = null;
              console.log("Save Action")
              this.createNew()
          } else {
              console.log("Edit Action")
              this.updateSite()
          }
      },
      createNew() {
          siteService.postSite(this.model).then((data) => {
              this.model.id = data.id;
              this.model.sitePin = 'Site PIN:  ' + data.sitePin;
              this.isNew = false;
              this.isEditing = false
              this.isErrorSaving = false
          })
          .catch(() => this.isErrorSaving = true)
          .finally(() => this.isSaving = false);
      },
      updateSite() {
          siteService.putSite(this.model).then(() => {
              console.log('Finished Update')
              this.isEditing = false
              this.isErrorSaving = false
          })
          .catch(() => this.isErrorSaving = true)
          .finally(() => this.isSaving = false);
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.b-card {
    max-width: 30rem
}
</style>