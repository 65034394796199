<template>
  <div class="mx-1 tm-color-body" style="margin-top: 1px">
        <login-component v-bind:auth="authClient"></login-component>
        <b-tabs content-class="mt-3" class="my-3">
            <b-tab title="Time Clock Records">
                <div class="mx-1">
                    <b-container fluid>
                        <b-row align-h="center">
                            <b-col md="3" class="m-2">
                                <label>Include Sites:</label>
                                <b-input v-model="punchSiteSearch" placeholder="Search Sites" ></b-input>
                            </b-col>
                            <b-col md="3" class="m-2">
                                <label>Include Vendors:</label>
                                <b-input v-model="punchVendorSearch" placeholder="Search Vendors"></b-input>
                            </b-col>
                            <b-col md="4" class="m-2">
                                <label>Clocks since:</label>
                                <b-form-datepicker 
                                    v-model="afterDate" 
                                    menu-class="p-2"
                                    calendar-width="100%"
                                    hide-header
                                    value-as-date
                                    weekday-header-format='narrow'
                                >
                                </b-form-datepicker>
                            </b-col>
                        </b-row>
                    </b-container>
                    <div class="sticky-top">
                        <b-row align-h="between">
                            <b-col style="text-align:right">
                                <b-button v-on:click="handleSaveReport" class="m-3 shadow" style="margin:auto">Save Report</b-button>
                            </b-col>
                        </b-row>
                    </div>
                    <div style="text-align:center; margin:auto">
                        <b-spinner :hidden="!isFetchingPunches"></b-spinner>
                    </div>
                    <time-punch v-for="clockin in clockins()" :key="clockin.id" v-bind="clockin"  class="my-4" style="margin:auto; text-align: left;"></time-punch>
                </div>
            </b-tab>
            <b-tab title="Manage Sites">          
                <div class="mx-1">
                    <b-container fluid style="text-align:center; margin:auto">
                        <b-row align-h="center">
                            <b-col md="3" class="m-2">
                                <label>Include Sites:</label>
                                <b-input v-model="siteNameSearch" placeholder="Search Sites" ></b-input>
                            </b-col>
                            <b-col md="3" class="m-2">
                                <label>Include Vendors:</label>
                                <b-input v-model="siteVendorSearch" placeholder="Search Vendors"></b-input>
                            </b-col>
                            <b-col md="2" style="text-align:right">
                                <b-button v-on:click="handleNewSiteButton" class="m-3 shadow" style="margin:auto">New Site</b-button>
                            </b-col>
                        </b-row>
                    </b-container>
                    <site v-for="site in siteList()" :key="site.id" v-bind="site"  class="my-4" style="margin:auto; text-align: center;"></site>
                </div>
            </b-tab>
            <b-tab title="Profile" style="text-align:center; margin:auto">
                <b-container fluid style="text-align:center; margin:auto">
                    <div style="text-align:center; margin:auto">
                        <user-profile style="text-align:left;" v-bind:auth="authClient"></user-profile>
                    </div>
                </b-container>
            </b-tab>
        </b-tabs>
  </div>  
</template>

<script>
import siteService from './SiteService'
import Site from './Site.vue'
import TimePunch from './TimePunch.vue'
import LoginComponent from './LoginComponent.vue'
import UserProfile from './Profile.vue'
import { Auth0Client } from "@auth0/auth0-spa-js";

let domain = process.env.VUE_APP_AUTH_HOST
let clientId = process.env.VUE_APP_AUTH_ID
let audience = process.env.VUE_APP_AUTH_AUDIENCE

export default {
    name: 'Admin',
    components: {
        Site,
        TimePunch,
        LoginComponent,
        UserProfile
    },
    props: {
        msg: String
    },
    data() {
        return { 
            searchTerm: "",
            punchSiteSearch: "",
            punchVendorSearch: "",
            siteNameSearch: "",
            siteVendorSearch: "",
            afterDate: new Date(),
            isFetchingPunches: false,
            authClient: null,
        }
    },
    beforeCreate: function() {
       
    },
    mounted: function() {
        siteService.auth0 = new Auth0Client({
            domain,
            audience,
            client_id: clientId,
            cacheLocation: 'localstorage',
            redirect_uri: `${window.location.origin}#/admin`
        });
        this.authClient = siteService.auth0;
        console.log(`Mounted Auth: ${siteService.auth0}`)
        this.configureInitialDate()
        this.fetchSites()
    },
    watch: {
        afterDate: function(val) {
            this.fetchPunches(val)
        }
    },
    methods: {
        fetchSites: async function() {
            siteService.getSites()
        },
        fetchPunches: async function(afterDate) {
            this.isFetchingPunches = true;
            siteService.getPunchesSinceDate(afterDate).finally(() => {
                this.isFetchingPunches = false;
            });
        },
        handleNewSiteButton() {
            this.siteNameSearch = ""
            this.siteVendorSearch = ""

            siteService.prependSite({
                id: "",
                name: "",
                address: "",
                sitePin: "",
                isNewSite: true
            });
        },
        siteList: function() {
           return siteService.sites()
                    .filter(next => {
                        return this.matchesIfPresent(next.name, this.siteNameSearch) 
                            && this.matchesIfPresent(next.vendorName, this.siteVendorSearch)
                    });
        },
        clockins() {
            // Note: Filter is not lazily evaluated in JS, thus by only calling filter once, we halve our runtime
            return siteService.clockins()
                    .filter(next => {
                        return this.matchesIfPresent(next.siteName, this.punchSiteSearch)
                          && this.matchesIfPresent(next.vendorName, this.punchVendorSearch)
                    });
        },
        configureInitialDate() {
            this.afterDate = new Date(this.afterDate.setDate(this.afterDate.getDate() - 1));
        },
        matchesIfPresent(next, targetString) {
            var match = true;
            if (targetString.length > 0) {
                match = next.toLowerCase().includes(targetString.toLowerCase());
            }
            return match;
        },
        handleSaveReport() {
            siteService.getReport(this.punchSiteSearch, this.punchVendorSearch, this.afterDate).then(response => {
                var fileUrl = window.URL.createObjectURL(new Blob([response.data]));
                var element = document.createElement('a')
                element.href = fileUrl;
                element.setAttribute('download', 'report.csv');
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);
            })
        }
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
