<template>
    <b-card v-if="!(isAuthenticated || isLoading)" border-variant="warning" align="center" style="max-width:25rem; margin:auto;" class="my-4">
        <b-card-title>
            Authentication Required
        </b-card-title>
        <b-button variant="secondary" v-on:click="login">Sign In </b-button>
    </b-card>
</template>

<script>
import { Auth0Client } from '@auth0/auth0-spa-js'

export default {
    name: 'LoginComponent',
    components: {
      // BIconExclamationTriangleFill
    },
    props: {
        auth: Auth0Client
    },
    data() {
        return {
            isLoading: false,
            isAuthenticated: false,
        }
    },
    watch: {
        auth: function() {
            console.log(`Login Prompt Update Auth: ${this.auth}`);
            if (this.auth) {
                this.isLoading = false;
                this.checkAuthStatus();
                this.handleRedirectIfNeeded();
            }
        }
    },
    mounted: function() {
        console.log(`Login Prompt Mount: ${this.auth}`);
    },
    methods: {
        login() {
            this.auth.loginWithRedirect({redirect_uri: `${window.location.origin}/#/admin`});
        },
        logout() {

        },
        async checkAuthStatus() {
            let user = await this.auth.getUser();
            console.log(`Auth User: ${JSON.stringify(user)}`)
            this.isAuthenticated = await this.auth.isAuthenticated();
            console.log(`Authentication Status: ${this.isAuthenticated}`);
        },
        async handleRedirectIfNeeded() {
            let didRecieveAuthCode = window.location.search.includes("code") && window.location.search.includes("state")
            if (!didRecieveAuthCode) { return; }

            try {
                console.log("Handling Redirect Callback...")
                let result = await this.auth.handleRedirectCallback();
                let isAuthenticated = await this.auth.isAuthenticated();
                console.log(`Auth redirect success: ${JSON.stringify(result)}`);
                console.log(`Is Authenticated: ${isAuthenticated}`);
                if (isAuthenticated) {
                    window.location.replace(`${window.location.origin}/#/admin`)
                }
            } catch (err) {
                console.log(`Auth Redirect: Error: ${JSON.stringify(err)}`);
            }
        }
    }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
